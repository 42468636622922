
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util } from "@/common/Views/comm/util";
import { BasicsClass } from "@/common/BasicsClass";
import { createElement } from "highcharts";
import { stringify } from "qs";

@Component({})
export default class HelloWorld extends Vue {
    @Prop() from: any;
    @Prop() refreshModule!: string;

    private frequency = 2;

    private mounted(): void {
        // this.appDom(7)
        // this.appDom(3)
        // this.appDom(5)
        // this.appDom(1)
        // this.appDom(8)
        // this.appDom(9)
        // this.appDom(2)
        // this.appDom(3)
    }

    @Watch("from", { immediate: true, deep: true })
    private async setDom() {
        // await this.clearDom();
        await this.getData();
    }

    @Watch("refreshModule")
    private async ifRefresh() {
        if (this.refreshModule == "ExistingNumber") {
            // await this.clearDom();
            await this.getData();
        }
    }
    private timer: any = null;
    private getData() {
        console.log("56as4d65as4d65as4d");
        return new Promise((resolve, reject) => {
            new BasicsClass().BasicGet(
                "/animal/web/bigScreenForCompanyNew/getAnimalCount",
                {
                    groupCode: this.from.id,
                    farmId: this.from.farmId,
                    animalTypeId: this.from.currentType,
                },
                false,
                true,
                (res: any) => {
                    // let list = res.data.animalCount.toString().split("");
                    // this.clearDom();
                    // for (let n in list) {
                    //     // console.log(list[n])
                    //     this.appDom(Number(list[n]));
                    //     resolve("");
                    // }
                    //通过计数器的方式将获取的数字从0开始递增到获取的数字,每次递增的数不一定是+1,首先要确保要在5s内完成
                    this.$refs.numberCase = document.getElementsByClassName(
                        "fields_num"
                    )[0];
                    if (this.$refs.numberCase.textContent == res.data.animalCount) {
                        resolve("");
                        return;
                    }
                    let number = res.data.animalCount;
                    let duration = 5000; // 5秒时间
                    let intervalTime = 20; // 每次递增间隔时间
                    let steps = Math.ceil(duration / intervalTime); // 总步数
                    let currentCount = 0;
                    let increment = number / steps; // 计算每一步应该增加的基础值

                    this.timer = setInterval(() => {
                        // 在基础步长的基础上，加入随机性（这里使用 80% 到 120% 的范围）
                        let randomFactor = 0.8 + Math.random() * 0.4;
                        let stepIncrement = increment * randomFactor;

                        currentCount += stepIncrement;

                        if (currentCount < number) {
                            this.$refs.numberCase.textContent = Math.floor(
                                currentCount
                            ); // 显示的数字取整
                        } else {
                            this.$refs.numberCase.textContent = number; // 到达最终数字
                            clearInterval(this.timer); // 停止计时器
                        }
                    }, intervalTime);
                    resolve("");
                }
            );
        });
    }

    private clearDom() {
        return new Promise((resolve, reject) => {
            let numberCase = document.getElementsByClassName("numberCase")[0];
            let domList = document.getElementsByClassName("jsDom");
            // if(domList.length == 0){
            //     resolve('')
            //     return
            // }
            while (document.getElementsByClassName("jsDom").length > 0) {
                // console.log("节点编号:"+ul.firstChild.innerHTML+" / 节点类型:"+ul.firstChild);
                numberCase.removeChild(numberCase.firstChild);
            }
            resolve("");
        });
    }

    // private getData(): void {
    //     new BasicsClass().BasicGet('/animal/web/bigScreenForCompanyNew/getAnimalCount', {}, false, true, (res: any) => {
    //         console.log(res)
    //     })
    // }
    //生命周期清除定时器
    beforeDestroy() {
        clearInterval(this.timer);
    }
    private appDom(nub): void {
        let numberCase = this.$refs.numberCase;
        //改了这里除了3
        // let largeFrameHeight = numberCase.offsetHeight;
        let largeFrameHeight = 50;
        let backImg = require("@/assets/top1.png");
        let dom = createElement("div");
        dom.style.height = largeFrameHeight + "px";
        dom.style.width = largeFrameHeight + "px";
        dom.style.background = `url(${backImg})`;
        dom.style.backgroundSize = "100% 100%";
        dom.style.fontSize = largeFrameHeight * 0.72 + "px";
        dom.style.fontWeight = "600";
        dom.style.color = "#00A4FF";
        dom.style.textAlign = "center";
        dom.style.lineHeight = largeFrameHeight + "px";
        dom.style.overflow = "hidden";
        dom.style.marginLeft = ".1rem";
        dom.className = "jsDom";
        numberCase.appendChild(dom);

        let sp = createElement("span");
        sp.style.display = "block";
        dom.appendChild(sp);
        sp.textContent = "0";
        if (nub == 0) {
            return;
        } else {
            this.frontRoll(sp, largeFrameHeight, 0, nub);
        }
    }

    private frontRoll(sp, domHeight, currentNub, nub): void {
        let alterNub = currentNub + 1;
        let a = 0;
        let b = (domHeight - domHeight * 0.72) / 2 + domHeight * 0.72;
        let time1 = window.setInterval(() => {
            a -= 1;
            sp.style.transform = `translate(0,${a + "px"})`;
            if (Math.abs(a) >= b) {
                sp.textContent = alterNub.toString();
                sp.style.transform = `translate(0,${b + "px"})`;
                if (alterNub == nub) {
                    this.rearRoll(sp, b, false, domHeight, alterNub, nub);
                } else {
                    this.rearRoll(sp, b, true, domHeight, alterNub, nub);
                }

                window.clearInterval(time1);
            }
        }, this.frequency);
    }

    private rearRoll(
        sp,
        currentHeight,
        goOn,
        domHeight,
        currentNub,
        nub
    ): void {
        let a = currentHeight;

        let time2 = window.setInterval(() => {
            a -= 1;
            sp.style.transform = `translate(0,${a + "px"})`;
            if (a <= 0) {
                sp.style.transform = `translate(0,0)`;
                if (goOn) {
                    this.frontRoll(sp, domHeight, currentNub, nub);
                }
                window.clearInterval(time2);
            }
        }, this.frequency);
    }
}
